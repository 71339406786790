import { PageProps } from '@inertiajs/core';
import { usePage } from '@inertiajs/react';
import { differenceInCalendarDays, differenceInHours, differenceInMinutes, differenceInMonths, format, isValid, parse, parseISO } from 'date-fns';
import { de, enUS, fr, nl } from 'date-fns/locale';
import { fromZonedTime } from 'date-fns-tz';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Country, Locale } from './types';

/** Maps app locale to browser locale */
const localeCodes = {
  [Locale.EN]: 'en-US',
  [Locale.NL]: 'nl',
};

const dateFnLocales = {
  en: enUS,
  nl,
  de,
  fr,
};

const decimalSeparators = {
  en: '.',
  nl: ',',
  de: ',',
  fr: ',',
};

export const zipCodeRegex = { NL: /^\d{4}\s*[A-Z]{2}$/i };

interface TranslationBundle {
  [key: string]: string | { [key: string]: string };
}
interface Translations {
  [namespace: string]: TranslationBundle;
}

const TranslationsContext = createContext({
  locale: Locale.EN,
});

export function TranslationsProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const { i18n } = useTranslation();
  const { locale: serverLocale, translations } = usePage<PageProps & { locale: Locale; translations: Translations; }>().props;

  /**
   * Store the active locale in React state so that we can trigger a rerender after
   * calling the async i18n.changeLanguage(). This way, everything that depends on
   * the current locale is updated at once (translations, number formatting, etc.)
   */
  const [locale, setLocale] = useState<Locale>(i18n.language as Locale);

  useEffect(() => {
    Object.keys(translations).map((namespace) => {
      i18n.addResourceBundle(serverLocale, namespace, translations[namespace]);
    });

    let isMounted = true;

    i18n.changeLanguage(serverLocale).then(() => {
      if (isMounted) {
        setLocale(serverLocale);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [serverLocale, translations]);

  return (
    <TranslationsContext.Provider value={{ locale }}>
      {children}
    </TranslationsContext.Provider>
  );
}

export const dateFormats = {
  en: {
    input_date_time: 'dd/MM/yyyy HH:mm',
    input_date: 'dd/MM/yyyy',
    input_date_short: 'dd/MM/yy',
    input_time: 'HH:mm',
    display_date_time: 'MMM d, yyyy, HH:mm',
    display_date: 'MMM d, yyyy',
    display_month: 'MMM yyyy',
    display_time: 'HH:mm',
    internal_date_time: 'yyyy-MM-dd HH:mm',
    internal_date: 'yyyy-MM-dd',
    internal_time: 'HH:mm',
  },
  nl: {
    input_date_time: 'dd-MM-yyyy HH:mm',
    input_date: 'dd-MM-yyyy',
    input_date_short: 'dd-MM-yy',
    input_time: 'HH:mm',
    display_date_time: 'd MMM yyyy, HH:mm',
    display_date: 'd MMM yyyy',
    display_month: 'MMM yyyy',
    display_time: 'HH:mm',
    internal_date_time: 'yyyy-MM-dd HH:mm',
    internal_date: 'yyyy-MM-dd',
    internal_time: 'HH:mm',
  },
};

type DateFormat = keyof typeof dateFormats['en'];

export function useLocale() {
  const { t } = useTranslation();
  const { locale } = useContext(TranslationsContext);
  const timeZone = 'Europe/Amsterdam';

  /**
   * Converts a Date object or an ISO date string to a display string.
   */
  const formatDate = useCallback((value: Date | string, dateFormat: DateFormat = 'display_date') => {
    const date = typeof value === 'string' ? parseISO(value) : value;

    return format(date, dateFormats[locale][dateFormat], {
      locale: dateFnLocales[locale],
    });
  }, [locale]);

  /**
   * Converts a number (cents) to a string.
   */
  const formatCurrency = useCallback((
    value: number,
    {
      currency = 'EUR',
      style = 'currency',
      minimumFractionDigits = 2,
      maximumFractionDigits = 2,
      ...options
    }: Intl.NumberFormatOptions = {},
  ) => (
    (value / 100)
      .toLocaleString(localeCodes[locale], {
        currency, style, minimumFractionDigits, maximumFractionDigits, ...options,
      })
      .replace(/\s/g, ' ')
      .replace('€', '€ ')
      .replace('€  ', '€ ')
      .replace('€ -', '- € ')
      .replace('-€', '- €')
      .trim()
  ), [locale]);

  /**
   * Converts a timestamp to a relative 'time ago' string.
   */
  const formatRelativeDate = useCallback((value: string) => {
    const date = new Date(value);
    const now = new Date();

    if (Intl === void 0 || typeof Intl.RelativeTimeFormat !== 'function') {
      // Browser not supported
      return date.toLocaleString();
    }

    // Format date relative to now using Intl.RelativeTimeFormat
    const formatter = new Intl.RelativeTimeFormat(localeCodes[locale], { numeric: 'auto' });

    const monthsDiff = differenceInMonths(date, now);

    if (monthsDiff !== 0) {
      return formatter.format(monthsDiff, 'month');
    }

    const daysDiff = differenceInCalendarDays(date, now);

    if (daysDiff !== 0) {
      return formatter.format(daysDiff, 'day');
    }

    const hoursDiff = differenceInHours(date, now);

    if (hoursDiff !== 0) {
      return formatter.format(hoursDiff, 'hour');
    }

    const minutesDiff = differenceInMinutes(date, now);

    if (minutesDiff !== 0) {
      return formatter.format(minutesDiff, 'minute');
    }

    return t('shared:locale.now');
  }, []);

  /**
   * Converts a number to a string.
   */
  const formatNumber = useCallback((
    value: number,
    options: Intl.NumberFormatOptions = { maximumFractionDigits: 2, useGrouping: true },
  ) => (
    value.toLocaleString(localeCodes[locale], options)
  ), [locale]);

  const formatPercentage = useCallback((
    value: number,
    options: Intl.NumberFormatOptions = { maximumFractionDigits: 2, useGrouping: true },
  ) => `${formatNumber(value, options)}%`, [locale]);

  /**
   * Converts a string to an integer (cents).
   */
  const parseCurrency = (value: string) => {
    // Remove everything but digits, commas and dots.
    const stripped = value.replace(/[^\d,.]/g, '');

    // Check if it ends with one or two decimals.
    const oneDecimal = stripped.match(/(,|\.)\d{1}$/);
    const twoDecimals = stripped.match(/(,|\.)\d{2}$/);

    // Remove all non-digits
    const digits = parseInteger(stripped.replace(/[^\d]/g, ''));

    const result = twoDecimals ? digits : (oneDecimal ? digits * 10 : digits * 100);

    const sign = value.startsWith('-') ? -1 : 1;

    return sign * result;
  };

  /**
   * Converts user input to a Date object.
   */
  const parseDate = useCallback((date: string, dateFormat: DateFormat) => {
    const parsedDate = parse(date, dateFormats[locale][dateFormat], new Date());

    if (isValid(new Date(parsedDate))) {
      return parsedDate;
    }

    return null;
  }, []);

  /**
   * Converts a date and a time string in a given format to UTC and returns an ISO 8601 string.
   */
  const toIsoDateTimeString = (date: string, time: string, dateFormat: DateFormat) => {
    const parsed = parseDate(`${date} ${time || ''}`.trim(), dateFormat);

    return parsed ? fromZonedTime(parsed, timeZone).toISOString().replace(/Z$/, '000Z') : null;
  };

  /**
   * Converts a string to an integer.
   */
  const parseInteger = (value: string) => (
    Math.floor(parseNumber(value))
  );

  /**
   * Converts a string to a float.
   */
  const parseNumber = (value: string) => (
    parseFloat(value
      // Strip everything that is not a digit, decimal separator, or dash
      .replace(new RegExp(`[^\\d${decimalSeparators[locale]}-]`, 'g'), '')
      // Make sure the decimal separator is a dot
      .replace(/[,]/g, '.'))
  );

  const defaultCountry: Country | null = locale === 'nl' ? 'NL' : null;

  return {
    locale,
    timeZone,
    formatRelativeDate,
    formatCurrency,
    formatDate,
    toIsoDateTimeString,
    formatNumber,
    formatPercentage,
    parseCurrency,
    parseDate,
    parseInteger,
    parseNumber,
    defaultCountry,
    dateFormats: dateFormats[locale],
  };
}
