import { Errors, PageProps, RequestPayload } from '@inertiajs/core';
import { ResourceLanguage } from 'i18next';

import { countries } from '../Backend/countries';

export interface Charity {
  id: string;
  title: string;
  description: string | null;
  website: string | null;
  email: string | null;
  phone: string | null;
  brand_color: string | null;
  logo_url: string | null;
  image_url: string | null;
  thumbnail_url: string | null;
  create_campaign_url: string;
  default_individual_description: string;
  default_team_description: string;
  privacy_statement: string | null;
  locale: Locale;
  url: string;
  google_analytics_id: string | null;
  slug: string;
  public: boolean;
}

export interface PublicCharity {
  id: string;
  title: string;
  brand_color: string | null;
  logo_url: string | null;
  image_url: string | null;
}

export interface QueryParams extends PageProps {
  redirect_url?: string | null;
  return_url?: string | null;
}

export interface SharedProps<Q extends QueryParams = QueryParams> extends PageProps {
  auth: {
    user: User | null;
  };
  params: Q;
  locale: Locale;
  errors: Errors | null;
  translations: {
    [key: string]: ResourceLanguage;
  };
  document?: {
    title: string;
  };
}

export enum Locale {
  EN = 'en',
  NL = 'nl',
}

export enum PaymentMethodType {
  APPLE_PAY = 'applepay',
  BANCOMATPAY = 'bancomatpay',
  BANCONTACT = 'bancontact',
  BANK_TRANSFER = 'banktransfer',
  BELFIUS = 'belfius',
  CREDITCARD = 'creditcard',
  DIRECT_DEBIT = 'directdebit',
  EPS = 'eps',
  GIFT_CARD = 'giftcard',
  GIROPAY = 'giropay',
  IDEAL = 'ideal',
  KBC = 'kbc',
  PAYPAL = 'paypal',
  PAYSAFECARD = 'paysafecard',
  PRZELEWY24 = 'przelewy24',
  SOFORT = 'sofort',
  TRUSTLY = 'trustly',
  OFFLINE = 'offline',
}

export enum PaymentStatus {
  PENDING = 'pending',
  PAID = 'paid',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  FAILED = 'failed',
}

export interface PaginationLinks {
  first: string;
  last: string;
  prev: string | null;
  next: string | null;
}

export interface Pagination<T = unknown> {
  data: T[];
  links: PaginationLinks;
  meta: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
    from?: number;
    to?: number;
    links?: {
      url: string | null;
      label: string;
      active: boolean;
    }[];
    path?: string;
  };
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  Nonbinary = 'nonbinary',
}

export enum CampaignType {
  Individual = 'individual',
  Team = 'team',
}

type LockedCampaignField = 'title' | 'type' | 'team' | 'project' | 'user';

export interface Campaign {
  id: string;
  type: CampaignType;
  title: string;
  image_url: string | null;
  thumbnail_url: string | null;
  start_date: string | null;
  end_date: string | null;
  funds_raised: number;
  fundraising_target: number | null;
  description: string | null;
  campaigner: string;
  first_name: string;
  last_name: string;
  company_name: string | null;
  donation_message: string | null;
  onboarding: boolean;
  locale: Locale;
  url: string;
  enabled: boolean;
  created_at: string;
  // Fields below are only present when authenticated as either the campaigner or back-end user
  email_opt_in?: boolean;
  marketing_email_opt_in?: boolean;
  locked_fields?: LockedCampaignField[];
}

export interface PublicCampaign {
  id: string;
  type: CampaignType;
  title: string;
  image_url: string | null;
  funds_raised: number;
  fundraising_target: number | null;
  url: string;
}

export interface GenderFields {
  gender: Gender | null;
}

export interface DateOfBirthFields {
  date_of_birth: string | null;
}

export interface PhoneFields {
  phone: string | null;
}

export type Country = typeof countries[number];

export interface AddressFields {
  street: string | null;
  house_number: string | null;
  extra_address_line: string | null;
  zip_code: string | null;
  city: string | null;
  country: Country | null;
}

export interface CampaignFields extends PhoneFields, GenderFields, DateOfBirthFields, AddressFields {}

export interface SentMail {
  id: string;
  subject: string;
  created_at: string;
}

export interface SentMailLink {
  id: string;
  url: string;
}

export interface MollieConnection {
  id: string;
  title: string | null;
  can_receive_payments: boolean;
  onboarding_url: string | null;
  dashboard_url: string;
  connected: boolean;
  deletable: boolean;
  profiles: MollieProfile[];
}

export interface MollieProfile {
  id: string;
  title: string;
  website: boolean;
  payment_methods: PaymentMethod[];
  verified: boolean;
  default: boolean;
}

export interface PaymentMethod {
  id: PaymentMethodType;
  issuers: Issuer[];
}

export interface Issuer {
  id: string;
  name: string;
}

export interface Event {
  id: string;
  title: string;
  logo_url: string | null;
  start_date: string;
  end_date: string;
  location: {
    id: string;
    description: string;
  } | null;
  website: string | null;
  fundraising_enabled: boolean;
  allow_other_charity: boolean;
  organiser: string;
  atleta_url: string;
  active: boolean;
  ended: boolean;
  starred: boolean;
  funds_raised: number;
  campaigns_count: number;
  slug: string;
}

export type CampaignField = 'fundraising_target' | 'date' | 'company_name' | 'gender' | 'date_of_birth' | 'phone' | 'address';

export const campaignFields: CampaignField[] = [
  'fundraising_target',
  'date',
  'gender',
  'date_of_birth',
  'company_name',
  'phone',
  'address',
];

export interface Ticket {
  id: string;
  title: string;
}

export interface Project {
  id: string;
  title: string;
  description: string | null;
  image_url: string | null;
  thumbnail_url: string | null;
  website: string | null;
  email: string | null;
  donation_message: string | null;
  mail_sender: string | null;
  mail_header_url: string | null;
  url: string | null;
  campaigns_count: number;
  funds_raised: number;
  locale: Locale;
  default: boolean;
  private: boolean;
  enabled: boolean;
  fields: CampaignField[];
  required_fields: CampaignField[];
  donation_fields: DonationField[];
  terms_url: string | null;
  open: boolean;
  ended: boolean;
  allowed_campaign_types: CampaignType[];
  default_campaign_type: CampaignType;
  end_date: string | null;
  allow_individuals: boolean;
  allow_teams: boolean;
  /** Indicates whether the project is visible to the outside world (enabled + not private) */
  visible: boolean;
  created_at: string;
}

export interface PublicProject {
  id: string;
  title: string;
  image_url: string | null;
}

export enum MailType {
  NewCampaign = 'new_campaign',
  DonationReceived = 'donation_received',
  DonationConfirmation = 'donation_confirmation',
  Reminder = 'reminder',
  TargetReached = 'target_reached',
  UpcomingEvent = 'upcoming_event',
  UpcomingEventReminder = 'upcoming_event_reminder',
  NewTeamMember = 'new_team_member',
}

export interface Mail {
  id: string;
  title: string;
  header_url: string | null;
  header_link: string | null;
  subject: string | null;
  sender: string | null;
  body: string | null;
  include_individuals: boolean;
  include_teams: boolean;
  min_days_old: number | null;
  created_after: string | null;
  min_funds_raised: number | null;
  max_funds_raised: number | null;
  start_at: string | null;
  end_at: string | null;
  enabled: boolean;
  created_at: string;
}

export interface User {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
}

export type DonationField = 'gender' | 'date_of_birth' | 'phone' | 'address';

export const donationFields: DonationField[] = [
  'gender',
  'date_of_birth',
  'phone',
  'address',
];

type LockedDonationField = 'campaign';

export interface DonationFields extends PhoneFields, GenderFields, DateOfBirthFields, AddressFields {}

export interface Donation {
  id: string;
  published_name: string | null;
  message: string;
  amount: number;
  payment_status: PaymentStatus;
  donation_invoice_id: string;
  created_at: string;
  active: boolean;
  locked_fields?: LockedDonationField[];
}

export interface Product {
  id: string;
  title: string;
  public_description: string | null;
  image_url: string | null;
  terms_url: string | null;
  campaigner_description: string | null;
  selling_price: number;
  cost_price: number;
  max_per_order: number;
  require_opt_in: boolean;
  required: boolean;
  start_at: string;
  end_at: string | null;
  is_campaign_product: boolean;
  required_fields: DonationField[];
  payment_methods: PaymentMethodType[];
  enabled: boolean;
  created_at: string;
  capacity?: number | null;
  sold_count?: number;
  deletable?: boolean;
}

export interface Order {
  id: string;
  /** Total donation amount */
  amount: number;
  first_name: string;
  last_name: string;
  full_name: string;
  company_name: string | null;
  email: string;
  street: string;
  house_number: string;
  extra_address_line: string | null;
  zip_code: string;
  city: string;
  country: Country;
  items: OrderItem[];
  created_at: string;
}

export interface OrderItem {
  id: string;
  quantity: number;
  product: {
    id: string;
    title: string;
  };
}

export interface Purchase {
  id: string;
  quantity: number;
  amount: number;
}

export interface Form {
  onSubmit: (data: RequestPayload) => Promise<unknown>;
  serverErrors?: Errors | null;
  loading?: boolean;
}
